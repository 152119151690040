import "./styles.scss"

import React from "react"

import Modal from "components/Modal"
import { navigate } from "gatsby"

const BookingModal = ({
  name,
  spec,
  img,
  booking,
  handleClose,
  setBookingModal,
  slug,
}) => {
  return (
    <div className="booking-modal">
      <Modal handleClose={handleClose}>
        <div className="booking-modal__content">
          <div className="booking-modal__img">
            <img src={img?.node?.sourceUrl} alt={img?.node?.altText} />
          </div>
          <div className="booking-modal__info">
            <p className="booking-modal__name">{name}</p>
            <p className="booking-modal__spec">{spec}</p>
            <button
              type="button"
              className="booking-modal__open"
              onClick={() => {
                setBookingModal(false)
                navigate(`/zespol/${slug}/`)
              }}
            >
              Dowiedz się więcej
            </button>
          </div>
        </div>
        <div className="booking-modal__widget">
          <iframe
            src={booking}
            width="320"
            height="496"
            frameborder="0"
            title={name}
          ></iframe>
        </div>
      </Modal>
    </div>
  )
}

export default BookingModal
