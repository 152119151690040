import "./styles.scss"

import React from "react"

import Xmark from "components/Icons/Xmark"

const Modal = ({ children, handleClose }) => {
  return (
    <div className="modal">
      <div className="modal__box">
        <button
          type="button"
          className="modal__close"
          onClick={e => handleClose(e)}
        >
          <Xmark />
        </button>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  )
}

export default Modal
