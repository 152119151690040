import "./styles.scss"

import React from "react"

const PageHeader = ({ header }) => {
  return (
    <section className="page-header">
      <div className="container">
        <h1 className="page-header__header">{header}</h1>
      </div>
    </section>
  )
}

export default PageHeader
