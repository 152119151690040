import "./styles.scss"

import React, { useState } from "react"

import Button from "components/Button"
import BookingModal from "../../specialists/BookingModal"

const SpecialistContent = ({ slug, img, specialist }) => {
  const [bookingModal, setBookingModal] = useState(false)

  const { name, specTitle, specDesc, booking } = specialist

  return (
    <section className="single-special">
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-0 offset-sm-2 col-sm-8 col-12">
            <div className="single-special__img">
              <img src={img?.node?.sourceUrl} alt={img?.node?.altText} />
            </div>
            <Button
              type="button"
              className="button-dark-green"
              onClick={() => {
                setBookingModal(true)
              }}
            >
              Umów wizytę
            </Button>
          </div>
          <div className="col-md-8 col-12">
            <div className="single-special__info">
              <p className="single-special__name">{name}</p>
              <p className="single-special__spec">{specTitle}</p>
              <div
                className="single-special__desc"
                dangerouslySetInnerHTML={{ __html: specDesc }}
              />
            </div>
          </div>
        </div>
        <Button
          type="button"
          className="button button-dark-green single-special__return"
          to="/zespol/"
        >
          Powrót do listy specjalistów
        </Button>
      </div>
      {bookingModal && (
        <BookingModal
          handleClose={() => setBookingModal(false)}
          name={name}
          spec={specTitle}
          img={img}
          booking={booking}
          setBookingModal={setBookingModal}
          slug={slug}
        />
      )}
    </section>
  )
}

export default SpecialistContent
