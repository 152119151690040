import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import SpecialistContent from "page_components/single-specialist/SpecialistContent"

const SingleSpecialist = ({ pageContext }) => {
  const breadcrumbs_data = [
    {
      name: "Zespół",
      href: "/zespol/",
    },
    {
      name: pageContext.data.title,
      href: `/zespol/${pageContext?.data?.slug}/`,
    },
  ]

  return (
    <Layout
      seo={{
        title: pageContext?.data?.seo?.title,
        description: pageContext?.data?.seo?.metaDesc,
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header={pageContext?.data?.title} />
      <SpecialistContent
        img={pageContext?.data?.featuredImage}
        specialist={pageContext?.data?.acfSpecialists}
        slug={pageContext?.data?.slug}
      />
    </Layout>
  )
}

export default SingleSpecialist
